body {
    margin: 0;
    padding: 0;
    /* background-image: url('background-image.jpg'); */
    background-size: cover;
    background-position: center;
    font-family: sans-serif;
    background-color: #000;
}

.App {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.App-header {
    background-color: #000;
    color: #fff;
    padding: 40px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 920px) {
        flex-wrap: wrap;
    }
}

.App-header h1 {
    margin-top: 0;
}

.download-btn-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    @media (max-width: 520px) {
        flex-wrap: wrap;
    }
}

.download-button a {
    margin: 15px;
}

.download-button img {
    width: 200px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transition: transform 0.2s ease-in-out;
}

.download-button img:hover {
    transform: scale(1.1);
}

main {
    padding: 40px;
}

.video-div {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 1120px) {
        width: 100%;
    }
}

.feature-section {
    background-color: #000;
    color: #fff;
    margin-bottom: 0;
    width: 450px;
    text-align: left;
    line-height: 1.5;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 520px) {
        width: 100%;
    }
}

.about-section {
    background-color: #000;
    color: #fff;
    padding: 40px;
    margin-top: 0;
}

.divider {
    border-top: 2px solid #ff8c00;
    margin: 0;
}

footer {
    text-align: center;
    background-color: #000;
    color: #fff;
    padding: 20px;

    .link{
        color: #fff;
        text-decoration: none;
        &:hover{
            color: #ff8c00;
        }
    }
}
